import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';

import logo from '../../../resources/images/cosmos_Logo-White.svg'
import { Link, useLocation } from 'react-router-dom';


type Anchor = 'right';

export default function TemporaryDrawer() {
    const location = useLocation();
    const currentPath = location.pathname;
    const [activeSection, setActiveSection] = React.useState(['entertainmentBanner', 'sportBanner', 'artsBanner']);

    const [state, setState] = React.useState({
        right: false,
    });

    const scrollToTop = () => {
        window.scrollTo(0, 0);
        setState({ ...state, right: false });
    };

    const toggleDrawer =
        (anchor: Anchor, open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {

                if (
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }
                setState({ ...state, [anchor]: open });
            };

    const handleLinkClick = (id: string) => {
        setState({ ...state, right: false });
        scrollToSection(id);
    };

    const scrollToSection = (id: any) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    React.useEffect(() => {
        const handleScroll = () => {
            const sectionIds = [
                'artsBanner',
                'artsAboutUs',
                'artsServices',
                'artsPortfolio',
                'artsContactUs',
                'entertainmentBanner',
                'entertainmentAboutUs',
                'entertainmentServices',
                'entertainmentPortfolio',
                'entertainmentClients',
                'entertainmentContactUs',
                'sportBanner',
                'sportAboutUs',
                'sportServices',
                'sportPortfolio',
                'sportContactUs',
            ];

            for (const id of sectionIds) {
                const element = document.getElementById(id);
                if (element) {
                    const rect = element.getBoundingClientRect();

                    if (rect.top - 100 <= 0) {
                        setActiveSection([id]);
                    }
                }
            }
        };

        setActiveSection(['entertainmentBanner', 'sportBanner', 'artsBanner']);
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <div>
            {(['right',] as const).map((anchor) => (
                <React.Fragment key={anchor}>
                    <div className="barButton">
                        <Button disableRipple onClick={toggleDrawer(anchor, true)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 448 512"> <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" /></svg>
                        </Button>
                    </div>
                    <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={() => toggleDrawer(anchor, false)}
                        className='DrawerTheme'
                    >
                        <div className='rightDrawer'>
                            <Link onClick={toggleDrawer(anchor, false)} className="rightDrawerLogo" to={'/'}>
                                <img src={logo} alt="Cosmos" loading="lazy" />
                            </Link>
                            <Button className='closeButton' disableRipple onClick={toggleDrawer(anchor, false)}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 506.95 491.32"><path d="m301.29,245.66L496.8,57.09c13.2-12.73,13.58-33.74.85-46.94-12.73-13.2-33.74-13.58-46.94-.85l-197.24,190.24L56.24,9.3C43.04-3.42,22.03-3.04,9.3,10.15c-12.73,13.2-12.35,34.21.85,46.94l195.51,188.57L10.15,434.24c-13.2,12.73-13.58,33.74-.85,46.94,6.51,6.75,15.2,10.15,23.9,10.15s16.6-3.09,23.04-9.3l197.24-190.24,197.24,190.24c6.44,6.21,14.75,9.3,23.04,9.3s17.38-3.4,23.9-10.15c12.73-13.2,12.35-34.21-.85-46.94l-195.51-188.57Z" /></svg>
                            </Button>
                        </div>
                        {
                            currentPath === '/' ?
                                <>
                                    <ul className='rightMenu topRightNav'>
                                        <li className="nav-item portfolioRight"><Link className={`nav-link`} title="Portfolio" to={'#homePortfolioAll '} onClick={toggleDrawer(anchor, false)}>Portfolio</Link></li>
                                        <li className="nav-item artsRight"><Link className={`nav-link ${location.pathname === "/Arts" ? "active" : ""}`} onClick={toggleDrawer(anchor, false)} title="Arts" to={'/arts'}>Arts</Link> </li>
                                        <li className="nav-item entertainmentRight"><Link className={`nav-link ${location.pathname === "/entertainment" ? "active" : ""}`} onClick={toggleDrawer(anchor, false)} to={'/entertainment'}>Entertainment</Link> </li>
                                        <li className="nav-item sportsRight"><Link className={`nav-link ${location.pathname === "/sports" ? "active" : ""}`} onClick={toggleDrawer(anchor, false)} to={'/sports'}>Sports</Link> </li>
                                    </ul>
                                </> : null
                        }
                        {
                            currentPath === '/arts' ?
                                <>
                                    <ul className='rightMenu artsNav'>
                                        <li className="nav-item"><Link className={`nav-link ${activeSection.includes('artsBanner') ? 'active' : ''}`} to={`/`} onClick={() => handleLinkClick('')}> Home </Link></li>
                                        <li className="nav-item"><Link className={`nav-link ${activeSection.includes('artsAboutUs') ? 'active' : ''}`} to={`#artsAboutUs`} onClick={() => handleLinkClick('artsAboutUs')}> About Us </Link></li>
                                        <li className="nav-item"><Link className={`nav-link ${activeSection.includes('artsServices') ? 'active' : ''}`} to={`#artsServices`} onClick={() => handleLinkClick('artsServices')}>Services</Link></li>
                                        <li className="nav-item"><Link className={`nav-link ${activeSection.includes('artsPortfolio') ? 'active' : ''}`} to={`#artsPortfolio`} onClick={() => handleLinkClick('artsPortfolio')}>Portfolio</Link></li>
                                        <li className="nav-item"><Link className={`nav-link ${activeSection.includes('artsContactUs') ? 'active' : ''}`} to={`#artsContactUs`} onClick={() => handleLinkClick('artsContactUs')}>Contact Us</Link> </li>
                                    </ul>
                                    <ul className='rightMenu topRightNav topBgLineNav'>
                                        <li className="nav-item portfolioRight"><Link className={`nav-link`} title="Portfolio" to={'/#homePortfolioAll '} onClick={toggleDrawer(anchor, false)}>Portfolio</Link></li>
                                        <li className="nav-item artsRight"><Link className={`nav-link ${location.pathname === "/arts" ? "active" : ""}`} onClick={toggleDrawer(anchor, false)} title="arts" to={'/arts'}>Arts</Link> </li>
                                        <li className="nav-item entertainmentRight"><Link className={`nav-link ${location.pathname === "/entertainment" ? "active" : ""}`} onClick={toggleDrawer(anchor, false)} to={'/entertainment'}>Entertainment</Link> </li>
                                        <li className="nav-item sportsRight"><Link className={`nav-link ${location.pathname === "/sports" ? "active" : ""}`} onClick={toggleDrawer(anchor, false)} to={'/sports'}>Sports</Link> </li>
                                    </ul>
                                </> : null
                        }
                        {
                            currentPath === '/entertainment' ?
                                <>
                                    <ul className='rightMenu entertainmentsNav'>
                                        <li className="nav-item"><Link className={`nav-link ${activeSection.includes('entertainmentBanner') ? 'active' : ''}`} to={`/`} onClick={() => handleLinkClick('')} >Home</Link> </li>
                                        <li className="nav-item"><Link className={`nav-link ${activeSection.includes('entertainmentAboutUs') ? 'active' : ''}`} to={`#entertainmentAboutUs`} onClick={() => handleLinkClick('entertainmentAboutUs')} >About Us</Link></li>
                                        <li className="nav-item"><Link className={`nav-link ${activeSection.includes('entertainmentServices') ? 'active' : ''}`} to={`#entertainmentServices`} onClick={() => handleLinkClick('entertainmentServices')}>Services</Link></li>
                                        <li className="nav-item"><Link className={`nav-link ${activeSection.includes('entertainmentPortfolio') ? 'active' : ''}`} to={`#entertainmentPortfolio`} onClick={() => handleLinkClick('entertainmentPortfolio')}>Portfolio</Link></li>
                                        <li className="nav-item"><Link className={`nav-link ${activeSection.includes('entertainmentClients') ? 'active' : ''}`} to={`#entertainmentClients`} onClick={() => handleLinkClick('entertainmentClients')}>Clients</Link></li>
                                        <li className="nav-item"><Link className={`nav-link ${activeSection.includes('entertainmentContactUs') ? 'active' : ''}`} to={`#entertainmentContactUs`} onClick={() => handleLinkClick('entertainmentContactUs')}>Contact Us</Link> </li>
                                    </ul>
                                    <ul className='rightMenu topRightNav topBgLineNav'>
                                        <li className="nav-item portfolioRight"><Link className={`nav-link`} title="Portfolio" to={'/#homePortfolioAll '} onClick={toggleDrawer(anchor, false)}>Portfolio</Link></li>
                                        <li className="nav-item artsRight"> <Link className={`nav-link ${location.pathname === "/arts" ? "active" : ""}`} onClick={() => { toggleDrawer(anchor, false); scrollToTop(); }} title="Arts" to={'/arts'} > Arts </Link> </li>
                                        <li className="nav-item entertainmentRight"> <Link className={`nav-link ${location.pathname === "/entertainment" ? "active" : ""}`} onClick={() => { toggleDrawer(anchor, false); scrollToTop(); }} to="/entertainment" > Entertainment </Link> </li>
                                        <li className="nav-item sportsRight"> <Link className={`nav-link ${location.pathname === "/sports" ? "active" : ""}`} onClick={() => { toggleDrawer(anchor, false); scrollToTop(); }} to="/sports" > Sports </Link> </li>
                                    </ul>
                                </> : null
                        }
                        {
                            currentPath === '/sports' ?
                                <>
                                    <ul className="rightMenu sportsNav">
                                        <li className="nav-item"><Link className={`nav-link ${activeSection.includes('sportBanner') ? 'active' : ''}`} to={`/`} onClick={() => handleLinkClick('')}>Home</Link> </li>
                                        <li className="nav-item"><Link className={`nav-link ${activeSection.includes('sportAboutUs') ? 'active' : ''}`} to={`#sportAboutUs`} onClick={() => handleLinkClick('sportAboutUs')}>About Us</Link></li>
                                        <li className="nav-item"><Link className={`nav-link ${activeSection.includes('sportServices') ? 'active' : ''}`} to={`#sportServices`} onClick={() => handleLinkClick('sportServices')}>Services</Link></li>
                                        <li className="nav-item"><Link className={`nav-link ${activeSection.includes('sportPortfolio') ? 'active' : ''}`} to={`#sportPortfolio`} onClick={() => handleLinkClick('sportPortfolio')}>Portfolio</Link></li>
                                        <li className="nav-item"><Link className={`nav-link ${activeSection.includes('sportContactUs') ? 'active' : ''}`} to={`#sportContactUs`} onClick={() => handleLinkClick('sportContactUs')} > Contact Us </Link> </li>
                                    </ul>
                                    <ul className='rightMenu topRightNav topBgLineNav'>
                                        <li className="nav-item portfolioRight"><Link className={`nav-link`} title="Portfolio" to={'/#homePortfolioAll '} onClick={toggleDrawer(anchor, false)}>Portfolio</Link></li>
                                        <li className="nav-item artsRight"> <Link className={`nav-link ${location.pathname === "/arts" ? "active" : ""}`} onClick={() => { toggleDrawer(anchor, false); scrollToTop(); }} title="Arts" to={'/arts'} > Arts </Link> </li>
                                        <li className="nav-item entertainmentRight"> <Link className={`nav-link ${location.pathname === "/entertainment" ? "active" : ""}`} onClick={() => { toggleDrawer(anchor, false); scrollToTop(); }} to="/entertainment" > Entertainment </Link> </li>
                                        <li className="nav-item sportsRight"> <Link className={`nav-link ${location.pathname === "/sports" ? "active" : ""}`} onClick={() => { toggleDrawer(anchor, false); scrollToTop(); }} to="/sports" > Sports </Link> </li>
                                    </ul>
                                </> : null
                        }
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}