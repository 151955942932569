import React from 'react';
import '../resources/css/OurPartners.css';
import partnersData from '../localhostDB/OurPartners.json';
interface IProps {
    clientsLineCl: string;
}

const OurPartners = ({ clientsLineCl }: IProps) => {
    return (
        <section className='OurPartnersBox'>
            <div className="OurPartnersLogo">
                <div className="container">
                    <div className="row">
                        <div className="col-12" data-aos="fade-down">
                            <div className={`sectionHeader text-center ${clientsLineCl}`}>
                                <h2>Our Clients</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center align-items-center OurPartnersLogoImg">
                        {partnersData.OurPartnersImg.map((partner, index) => (
                            <div
                                key={index}
                                className="col-sm-6 col-md-1-5 col-lg-1-5 my-3 text-center"
                                data-aos="fade-down"
                                data-aos-delay={`${(index + 1) * 100}`}
                            >
                                <div className="OurPartnersLogoImgInner">
                                    <img
                                        src={partner.PartnersImg}
                                        className="img-fluid"
                                        alt={partner.ImgAlt}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OurPartners;
